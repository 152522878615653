<template>
    <footer>
        <div class="my-3 text-xs">
            <div :class="{ flex: route.meta.name === NuxtPageNames.LOGIN }">
                <div>
                    <span>
                        {{ new Date().getFullYear() }}
                        <font-awesome-icon
                            :icon="['fad', 'copyright']"
                            style="--fa-primary-color: white"
                        />
                        <template
                            v-if="route.meta.name === NuxtPageNames.LOGIN"
                        >
                            <nuxt-link
                                class="hover:underline"
                                to="https://smake.com"
                            >
                                Smake
                            </nuxt-link>
                        </template>
                        <template v-else> Smake</template>
                    </span>
                    <template v-if="menuItems">
                        <span v-for="link in menuItems" :key="link.name">
                            <span class="mx-2">•</span>
                            <nuxt-link class="hover:underline" :to="link.href">
                                {{ link.name }}
                            </nuxt-link>
                        </span>
                    </template>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { NuxtPageNames } from "~/enums/routes";

const { t } = useI18n();

const route = useRoute();
const menuItems = computed(() => {
    if (route.name === "hash-login") {
        return [
            {
                name: t("shop/frontend.footer.imprint"),
                href: "https://login.smake.com/de/imprint",
            },
            {
                name: t("shop/frontend.footer.privacy"),
                href: "https://login.smake.com/de/privacy",
            },
            {
                name: t("system.legal_documents.terms_and_conditions"),
                href: "https://login.smake.com/documents/terms-and-conditions_DE.pdf",
            },
            {
                name: t(
                    "system.legal_documents.contract_of_processing_of_data",
                ),
                href: "https://login.smake.com/documents/contract-processing-of-data_DE.pdf",
            },
            {
                name: t("system.legal_documents.service_level_agreement"),
                href: "https://login.smake.com/documents/service-level-agreement_DE.pdf",
            },
            {
                name: t(
                    "system.legal_documents.technical_and_organisational_measures",
                ),
                href: "https://login.smake.com/documents/technical-and-organisational-measures_DE.pdf",
            },
        ];
    }

    return [];
});
</script>
